import React, {useState, useEffect, useCallback} from 'react';
import {Container, Tabs, Tab, Alert, Button} from 'react-bootstrap';
import ButtonGoogleLogin from './components/Login/ButtonGoogleLogin';

import AdmissionList from './AdmissionList';
import PreviousSummaries from './PreviousSummaries';
import TodaySummary from './TodaySummary';
import AutoRefresh from './AutoRefresh';
import KeepAlive from './KeepAlive';
import PageVisibility from './PageVisibility';
import NewAdmissionModal from './NewAdmissionModal'; // Import the new modal component
import {Route, Routes} from 'react-router-dom'; // Import routes and route
import './App.css';

function App() {
	const [admissions, setAdmissions] = useState([]);
	const [summary, setSummary] = useState(null);
	const [allAdmissions, setAllAdmissions] = useState([]);
	const [key, setKey] = useState('today');
	const [alert, setAlert] = useState({show: false, variant: '', message: ''});
	const [showModal, setShowModal] = useState(false); // State to control modal visibility

	const refreshInterval = 30;

	const showAlert = useCallback((variant, message) => {
		setAlert({show: true, variant, message});
		setTimeout(() => setAlert({show: false, variant: '', message: ''}), 3000);
	}, []);

	const fetchAdmissions = useCallback(async () => {
		const response = await fetch('https://admissions.everyonespinballclub.workers.dev/api/admissions');
		const data = await response.json();
		setAdmissions(groupAdmissions(data));
	}, []);

	const fetchSummary = useCallback(async () => {
		const response = await fetch('https://admissions.everyonespinballclub.workers.dev/api/summary');
		const data = await response.json();
		setSummary(data);
	}, []);

	const fetchAllAdmissions = useCallback(async () => {
		const response = await fetch('https://admissions.everyonespinballclub.workers.dev/api/admissions/all');
		const data = await response.json();
		setAllAdmissions(data);
	}, []);

	const handleRefresh = useCallback(() => {
		if (key === 'today') {
			fetchAdmissions();
			fetchSummary();
		} else if (key === 'previous') {
			fetchAllAdmissions();
		} else if (key === 'all') {
			fetchAllAdmissions();
		}
	}, [key, fetchAdmissions, fetchSummary, fetchAllAdmissions]);

	useEffect(() => {
		handleRefresh();
	}, [handleRefresh]);

	const groupAdmissions = (admissions) => {
		const sortedAdmissions = admissions.sort((a, b) => new Date(a.time) - new Date(b.time));
		const groups = [];
		let currentGroup = [];
		let lastTime = null;

		sortedAdmissions.forEach((admission) => {
			const currentTime = new Date(admission.time);
			if (lastTime && (currentTime - lastTime) / 1000 > 15) {
				groups.push(currentGroup);
				currentGroup = [];
			}
			currentGroup.push(admission);
			lastTime = currentTime;
		});

		if (currentGroup.length > 0) groups.push(currentGroup);
		return groups.reverse();
	};

	const formatTime = (dateString) => {
		const options = {hour: 'numeric', minute: 'numeric', hour12: true};
		return new Date(dateString).toLocaleTimeString([], options).toLowerCase();
	};

	const handleOpenModal = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handleDeleteAdmission = async (id) => {
		try {
			await fetch(`https://admissions.everyonespinballclub.workers.dev/api/admissions/${id}`, {
				method: 'DELETE',
			});
			showAlert('danger', 'Admission Deleted');
			handleRefresh(); // Refresh after deleting an admission
		} catch (error) {
			console.error('Error deleting admission:', error);
			showAlert('danger', 'Failed to delete admission');
		}
	};

	return (
		<Container>
			<h1>Pinball Parlor Admissions</h1>

			{alert.show && (
				<div className="overlay-alert">
					<Alert variant={alert.variant} onClose={() => setAlert({show: false})} dismissible>
						{alert.message}
					</Alert>
				</div>
			)}

			{/* Button to open the new admission modal */}
			<div className="d-flex justify-content-center mb-4">
				<Button variant="primary" onClick={handleOpenModal} style={{width: '33%'}}>
					New Admission
				</Button>
			</div>

			<Routes>
				<Route
					path="/"
					element={
						<>
							<Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-4">
								<Tab eventKey="today" title="Events">
									<TodaySummary admissions={admissions} summary={summary} handleDeleteAdmission={handleDeleteAdmission} formatTime={formatTime} />
								</Tab>
							</Tabs>
							<div className="d-flex justify-content-center mt-4">
								<ButtonGoogleLogin />
							</div>
						</>
					}
				/>

				<Route
					path="/secretstuff"
					element={
						<Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-4">
							<Tab eventKey="today" title="Today's Summary">
								<TodaySummary admissions={admissions} summary={summary} handleDeleteAdmission={handleDeleteAdmission} formatTime={formatTime} />
							</Tab>

							<Tab eventKey="previous" title="Previous Days' Summaries">
								<PreviousSummaries allAdmissions={allAdmissions} />
							</Tab>

							<Tab eventKey="all" title="All Admissions">
								<AdmissionList admissions={allAdmissions} onDelete={handleRefresh} />
							</Tab>
						</Tabs>
					}
				/>
			</Routes>

			<NewAdmissionModal show={showModal} handleClose={handleCloseModal} showAlert={showAlert} handleRefresh={handleRefresh} />

			<AutoRefresh refreshInterval={refreshInterval} onRefresh={handleRefresh} />
			<KeepAlive />
			<PageVisibility />
		</Container>
	);
}

export default App;
